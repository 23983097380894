@import '../../root.css';

.app {
    display: flex;
    margin: 0;
    padding: 0;
    height: var(--container-height);
    width: 100vw;
    position: absolute;
    top: 7vh;
    left: 0px;
    font-family: "Roboto Slab", serif;
    font-optical-sizing: auto;
    font-weight: 300;
    font-style: normal;
}