@import '../../root.css';

.output-container {
    height: var(--container-height);
    width: 450px;
    background-color: var(--main-bg-color);
    position: absolute;
    top: 0vh;
    right: 450px;
    border-right: 2px solid var(--border-color);
    border-left: 2px solid var(--border-color);
    box-sizing: content-box;
}

.output-header {
    height: 5vh;
    width: 450px;
    background-color: var(--header-bg-color);
    position: relative;
    display: flex;
    justify-content: space-between;
    font-size: 16px;
    line-height: 5vh;
    font-weight: 500;
    color: var(--text-color);
    border-top: 2px solid var(--border-color);
    border-bottom: 2px solid var(--border-color);
    user-select: none;
    cursor: grab;
    box-sizing: content-box;
}

.output-header span {
    padding: 0 15px;
}

.coderender {
    min-height: 300px;
    min-width: 400px;
    max-height: 300px;
    max-width: 400px;
    background-color: #fff;
    position: relative;
    margin-top: 25px;
    box-sizing: content-box;
    left: 50%;
    transform: translateX(-50%);
}

.renderhere {
    height: 300px;
    width: 400px;
}