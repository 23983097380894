@import '../../root.css';

.row {
    height: 8vh;
    width: 100vw;
    background-color: var(--main-bg-color);
    position: absolute;
    bottom: 0px;
    left: 0px;
    color: var(--text-color);
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 2px solid var(--border-color);
    user-select: none;
    box-sizing: border-box;
}

.submit-button {
    padding: 1.2vh 2vw;
    appearance: none;
    outline: none;
    border: none;
    background-color: #0060ca;
    color: #fff;
    border-radius: 5px;
    font-weight: 700;
    font-size: 20px;
    cursor: pointer;
    transition: 0.2s ease;
    box-shadow: inset 1px 1px 2px rgba(255, 255, 255, 0.1);
    margin-left: 1vw;
}

.disabled {
    opacity: 0.6;
    cursor: not-allowed;
}

.submit-button:hover {
    transform: translateY(-2px);
}

.submit-button.disabled:hover {
    transform: none;
}

.row div {
    line-height: 24px;
    margin: 15px;
    font-size: 18px;
}

.row div a {
    font-weight: 500;
    text-decoration: none;
    color: var(--text-color);
    cursor: pointer;
    appearance: none;
}