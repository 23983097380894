@import '../../root.css';

.editor-container {
    height: var(--container-height);
    width: calc(100vw - 900px);
    background: var(--main-bg-color);
    position: absolute;
    top: 0;
    left: 0;
    box-sizing: content-box;
}

.editor-header {
    height: 5vh;
    width: calc(100vw - 900px);
    background-color: var(--header-bg-color);
    position: relative;
    display: flex;
    justify-content: space-between;
    font-size: 16px;
    line-height: 5vh;
    font-weight: 500;
    color: var(--text-color);
    border-top: 2px solid var(--border-color);
    border-bottom: 2px solid var(--border-color);
    user-select: none;
    cursor: grab;
    box-sizing: content-box;
}

.editor-header span {
    padding: 0 15px;
}

.editor-content {
    height: calc(var(--container-height) - 5vh - 14px);
    width: calc(100vw - 900px);
    position: relative;
    top: 10px;
    background-color: var(--main-bg-color);
    box-sizing: content-box;
}