@import url('https://fonts.googleapis.com/css2?family=Anton+SC&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Anton+SC&family=Teko:wght@300..700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@100..900&display=swap');

:root {
    --main-bg-color: #07080b;
    --text-color: #fff;
    --header-bg-color: #2a313a;
    --border-color: rgba(160, 179, 198, 0.2);
    --container-height: 85vh;
    --alert-box-width: 380px;
    --result-container-height: 400px;
    --result-container-width: 400px;
}

.playfair-display-author {
    font-family: "Playfair Display", serif;
    font-optical-sizing: auto;
    font-weight: 300;
    font-style: normal;
  }
