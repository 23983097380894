@import '../../root.css';

.top-row {
    height: 7vh;
    width: 100vw;
    background-color: var(--main-bg-color);
    position: absolute;
    top: 0vh;
    left: 0px;
    text-align: center;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.home-button {
    padding: 1vh 1.5vw;
    appearance: none;
    outline: none;
    border: none;
    background-color: #fff;
    color: var(--main-bg-color);
    border-radius: 5px;
    font-weight: 700;
    font-size: 20px;
    cursor: pointer;
    box-shadow: inset 1px 1px 2px rgba(255, 255, 255, 0.1);
    margin-left: 1vw;
}

.banner-text {
    line-height: 7vh;
    font-size: 32px;
    font-family: "Anton SC", sans-serif;
    font-weight: 400;
    font-style: normal;
    color: #fff;
    user-select: none;
    cursor: grab;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
}

.visitor-count {
    line-height: 7vh;
    font-size: 18px;
    color: var(--text-color);
    font-family: "Roboto Slab", serif;
    font-optical-sizing: auto;
    font-weight: 500;
    font-style: normal;
    margin-right: 1vw;
    transition: 0.2 ease;
    user-select: none;
}