@import '../../root.css';

.result-body {
    height: 100vh;
    width: 100vw;
    background-color: #fff;
    position: absolute;
    top: 0;
    left: 0;
}

.result-container {
    height: var(--result-container-height);
    width: var(--result-container-width);
    position: absolute;
    top: calc((100vh - var(--result-container-height))/2);
    left: calc((100vw - var(--result-container-width))/2);
    /* background: linear-gradient(to right, #ff7e5f, #feb47b); */
    background: #36454F;
    color: #fff;
    border-radius: 2px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    text-align: center;
}

.exit {
    color: #fff;
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;
}

.text {
    padding: 10px;
    user-select: none;
}

.text1 {
    font-size: 30px;
    font-weight: 500;
}

.text2 {
    font-size: 22px;
}

.text3 {
    font-size: 42px;
    font-weight: 700;
}