@import '../../root.css';

.landing-container {
    height: 100vh;
    width: 100vw;
    position: absolute;
    top: 0px;
    left: 0px;
    background-color: var(--main-bg-color);
    color: #fff;
    overflow-y: auto;
}

.landing-container::-webkit-scrollbar {
    display: none;
}

.landing-header {
    height: 7vh;
    width: 100vw;
    background-color: var(--main-bg-color);
    color: #fff;
    position: fixed;
    z-index: 10;
    display: flex;
    justify-content: center;
    align-items: center;
}

.landing-title {
    font-size: 32px;
    font-family: "Anton SC", sans-serif;
    font-weight: 400;
    font-style: normal;
    color: #fff;
    user-select: none;
    cursor: grab;
}

.landing-count {
    position: absolute;
    right: 1vw;
    font-family: "Roboto Slab", serif;
    font-optical-sizing: auto;
    font-weight: 500;
    font-style: normal;
    font-size: 18px;
    transition: 0.2 ease;
    user-select: none;
}

.landing-box {
    width: 100vw;
    position: absolute;
    top: 7vh;
    left: 0px;
    display: flex;
    flex-wrap: wrap;
}

.challenge-box {
    height: 210px;
    width: 210px;
    position: relative;
    background-color: var(--header-bg-color);
    border-radius: 5px;
    border: 2px solid var(--border-color);
    cursor: pointer;
    margin: 21px;
    user-select: none;
    font-family: "Teko", sans-serif;
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;    
}

.challenge-box:hover {
    /* box-shadow: 0 0 10px rgba(255,233,0), 0 0 0 1px #ffdf00; */
    box-shadow: 0 0 10px rgba(255,255,255), 0 0 0 1px #ffffff;
    transform: scale(1.01);
}

.challenge-img {
    height: 135px;
    width: 180px;
    position: absolute;
    top: 15px;
    left: 15px;
    border-radius: 5px;
}

.challenge-id {
    width: 210px;
    line-height: 30px;
    color: #fff;
    position: absolute;
    top: 160px;
    text-align: center;
    font-size: 1.2rem;
}